import Swiper from "swiper";
import { Autoplay, FreeMode } from "swiper/modules";

const advantagesContainer = <HTMLElement>document.querySelector(".advantages-section");
let scrollActive = false;

Swiper.use([FreeMode, Autoplay]);

const swiper = new Swiper(".advantages-swiper", {
    direction: "horizontal",
    loop: false,
    slidesPerView: "auto",
    freeMode: { enabled: true, sticky: false, momentum: false },
    spaceBetween: 32,
    autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
    },
});

if (advantagesContainer) {
    const autoTranslate = () => {
        const rect = advantagesContainer.querySelector(".swiper-wrapper").getBoundingClientRect();
        const slides = advantagesContainer.querySelectorAll(".swiper-slide");
        // if already scrolled left on page load we need the distance between the first slide and the last
        const firstRect = slides[0].getBoundingClientRect();
        const lastRect = slides[slides.length - 1].getBoundingClientRect();
        const maxTranslate = Math.abs(lastRect.right - firstRect.left - rect.width);
        const wrapper = advantagesContainer.querySelector(".swiper-wrapper").getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const bottom = wrapper.bottom + windowHeight * 0.1;
        const currentTranslate = swiper.getTranslate();
        const topBorder = bottom + wrapper.height;

        if (topBorder < windowHeight) {
            swiper.translateTo(maxTranslate * -1, 250, true, true);
        } else if (bottom > windowHeight && currentTranslate !== 0) {
            swiper.translateTo(0, 250, true, true);
        } else if (topBorder >= windowHeight && bottom <= windowHeight) {
            const delta = bottom - windowHeight;
            const percentage = delta / wrapper.height;
            swiper.translateTo(maxTranslate * percentage, 250, true, true);
        }
    };

    const addScrollListener = () => {
        if (swiper) {
            swiper.autoplay.stop();
            scrollActive = true;
            window.addEventListener("scroll", autoTranslate);
        }
    };

    // translate on scroll should only be active for laptops and desktops
    if (!scrollActive && window.innerWidth > 992) {
        addScrollListener();
    }

    window.addEventListener("resize", () => {
        if (!scrollActive && window.innerWidth > 992) {
            addScrollListener();
        }
        if (scrollActive && window.innerWidth < 992) {
            scrollActive = false;
            swiper.autoplay.start();
            window.removeEventListener("scroll", autoTranslate);
        }
    });
}
