import { getCSRF } from "./api/session";
// Load CSRF Token
getCSRF();

import "./AdvantageSection";
import "./FAQs";
import "./HomepageDomainSearch";
import "./Navigation";
import "./ScrollToTop";
import "./SwiperGalleries";
import "./ProfileLink";
import ScrollToTop from "./ScrollToTop";
import { handleCartContent } from "../react/helpers/helpers";
import PlausibleEvents from "./PlausibleEvents";
import "./Redirect";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

const backToTopButton = document.querySelector<HTMLElement>("#BackToTop");
if (backToTopButton) {
    new ScrollToTop(backToTopButton);
}

handleCartContent(false);

new PlausibleEvents();
