const faqSections = document.querySelectorAll(".faqs-section");

faqSections.forEach((section) => {
    const entries = section.querySelectorAll(".faq-entry");

    entries.forEach((entry) => {
        const expand = entry.querySelector(".faq-top");
        const faqWrapper = entry.querySelector(".faq-wrapper");
        expand.addEventListener("click", () => {
            const open = expand.querySelector(".expand").classList.toggle("open");
            faqWrapper.classList.toggle("open", open);
        });
    });
});
