import { getSession } from "./api/session";

const dashboardLink = <HTMLLinkElement>document.querySelector("#DashboardLink");

const setSession = async () => {
    const session = await getSession();

    if (session) {
        dashboardLink.href = dashboardLink.dataset.dashboardLink;
        dashboardLink.classList.add("profile");
        dashboardLink.classList.remove("login");
    } else {
        dashboardLink.href = dashboardLink.dataset.loginLink;
        dashboardLink.classList.add("login");
        dashboardLink.classList.remove("profile");
    }
    dashboardLink.classList.remove("disabled");
};

setSession();
