const navButton = <HTMLElement>document.querySelector(".nav-button");
const header = document.querySelector("#Header");
navButton.addEventListener("click", () => {
    const menu = document.getElementById(navButton.getAttribute("aria-controls"));
    const open = navButton.classList.toggle("open");
    menu.classList.toggle("closing", !open);
    menu.classList.toggle("open", open);
    navButton.blur();

    window.setTimeout(() => {
        menu.classList.remove("closing");
    }, 250);
});

window.addEventListener("scroll", () => {
    header.classList.toggle("collapsed", window.scrollY >= 110);
    const verifyBanner = document.querySelector(".verify-banner");
    if (verifyBanner) {
        verifyBanner.classList.toggle("collapsed", window.scrollY >= 110);
    }
});
