const redirectSections = document.querySelectorAll(".redirect-section");

const cartStorage = window.localStorage.getItem("at.reg.cart");
const cart = JSON.parse(cartStorage);

if (cart && cart.state.domains.length > 0) {
    redirectSections.forEach((section) => {
        const link = <HTMLLinkElement>section.querySelector(".go-to-cart");
        const buttonText = link.innerHTML;
        let countdown = 5;
        link.classList.remove("hidden");
        link.innerHTML = `${buttonText}${countdown}`;

        const interval = setInterval(() => {
            if (countdown === 0) {
                clearInterval(interval);
                window.location.href = link.href;
            } else {
                countdown -= 1;
                link.innerHTML = `${buttonText}${countdown}`;
            }
        }, 1000);
    });
}
