import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import Swiper from "swiper";
import { Autoplay, Pagination } from "swiper/modules";

document.querySelectorAll<HTMLDivElement>(".swiper.testimonial-list").forEach((container) => {
    new Swiper(container, {
        modules: [Pagination, Autoplay],
        loop: false,
        breakpoints: {
            640: {
                slidesPerView: 1,
                centeredSlides: true,
            },
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
        spaceBetween: 32,
        autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
        },
        pagination: { el: ".swiper-pagination", clickable: true },
    });
});
