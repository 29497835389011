const searchSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".homepage-domain-search");
searchSections.forEach((searchSection) => {
    const link = searchSection.dataset.link;
    const input = <HTMLInputElement>searchSection.querySelector(".domain-search-input");
    const button = <HTMLButtonElement>searchSection.querySelector(".search");

    if (input && button) {
        button.addEventListener("click", () => {
            window.location.href = `${link}?q=${input.value}`;
        });

        input.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
                window.location.href = `${link}?q=${input.value}`;
            }
        });
    }
});
