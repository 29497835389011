export default class PlausibleEvents {
    constructor() {
        const items = <NodeListOf<HTMLElement>>document.querySelectorAll("[data-plausible-event]");
        items.forEach((item) => this.register(item));
    }

    private register(element: HTMLElement) {
        const [event, name] = element.dataset.plausibleEvent.split(":");
        const props = element.dataset.plausibleProps;
        element.addEventListener(event, () => this.triggerEvent(name, props));
    }

    private triggerEvent(event: string, props = undefined) {
        // @ts-ignore
        window.plausible =
            // @ts-ignore
            window.plausible ||
            function () {
                // @ts-ignore
                if (!window.plausible) window.plausible = { q: [] };
                // @ts-ignore
                (window.plausible.q = window.plausible.q || []).push(arguments);
            };

        // @ts-ignore
        const plausible = window.plausible;

        if (props !== undefined) {
            plausible(event, { props: { name: props } });
        } else {
            plausible(event);
        }
    }
}
